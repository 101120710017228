@import '../../../Styles/settings';

.banner {
  background-color: $clear-blue;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.buttonContainer {
  margin-top: 16px;
  display: flex;
  gap: 24px;
  flex-flow: wrap;
}

.bannerContent {
  margin-bottom: 18px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.mainText {
  margin-top: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-right: 24px;
  color: #00153d;
}

.btnText {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}

.iconWrapper {
  height: 100%;
}

.alertIcon {
  margin: 16px 16px 0px 16px;
  flex-shrink: 0;
}

// desktop media queries
@media #{$md-up} {
  .bannerContent {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    height: 56px;
  }

  .buttonContainer {
    justify-content: flex-end;
    margin-right: 24px;
    flex-grow: 1;
    margin-top: 0;
    flex-flow: unset;
  }
  .banner {
    align-items: center;
    column-gap: 16px;
    margin: 0px 0px 24px 0px;
  }
  .alertIcon {
    margin: 0;
    flex-shrink: 0;
  }

  .iconWrapper {
    display: flex;
    margin-left: 16px;
  }

  .mainText {
    margin-top: 0;
  }
}
