@import '../../Styles/settings';

@keyframes svgAnimation {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0px);
  }
}

.root {
  padding-top: $mobile-header-height;
}

.rootPaddingBottom {
  padding-bottom: $verticleBlockPaddingXS;
}

.productListSection {
  padding: 16px 0 0;
}

.heading {
  font-size: 28px;
  margin-bottom: 24px;
}

.contentAreaWrapperPaddingTop {
  padding-top: $verticleBlockPaddingXS;
}

.backLink {
  text-decoration: none;
  font-size: 16px;
  line-height: 19px;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.h2 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.backLinkTextStrong {
  font-weight: 500;
}

.category-block-container {
  --flex-wrap: nowrap;

  @media #{$md-up} {
    --flex-wrap: wrap;
  }

  display: flex;
  align-items: flex-start;
  flex-wrap: var(--flex-wrap);
  flex-direction: row;
  gap: 24px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: pan-x pan-y;
  padding-left: 16px;
  margin-bottom: 24px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.content-block-container {
  margin-bottom: 24px;
}

@media #{$md-up} {
  .root {
    padding-top: $standard-header-height-md-up;
  }

  .rootPaddingBottom {
    padding-bottom: $verticleBlockPaddingMD;
  }

  .heading {
    font-size: 36px;
  }

  .contentAreaWrapperPaddingTop {
    padding-top: $verticleBlockPaddingMD;
  }

  .productListSectionContainer {
    display: flex;
  }

  .productListSection {
    padding-top: 29px;
  }

  .backLink {
    font-size: 20px;
    line-height: 26px;
  }
  .backLink {
    &:hover {
      svg {
        animation: svgAnimation 0.3s $main-timing-function;
      }
    }
  }
}
